<template>
  <b-card no-body class="p-1" ref="userAccounts">
    <b-overlay
      :show="state.loading"
      rounded="sm"
    >
      <div class="demo-spacing-0 m-1 d-flex justify-content-between align-items-center"
           dir="rtl"
      >
        <h3 v-if="$route.name === 'show-accounts'">
          لیست زیر مجموعه ها
        </h3>
        <template v-else>
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" @click="isActive=true">
            <feather-icon icon="FilterIcon"/>
            فیلتر
          </b-button>
        </template>
      </div>

      <search-and-filter
        :is-active.sync="isActive"
        :options="columns"
        @filter="getData(1, perPage)"
        @reset="getData(1, perPage)"
      />

      <b-modal
        ref="msg-modal"
        cancel-variant="outline-secondary"
        ok-title="ارسال پیام"
        cancel-title="لغو"
        centered
        title="پیام جدید"
        @ok.prevent="sendMessage"
        v-model="messageModal"
        size="lg"
      >
        <b-overlay :show="loading">
          <b-form>
            <b-form-group label="عنوان پیام">
              <b-form-input
                type="text"
                placeholder="عنوان"
                v-model="message.title"
              />
            </b-form-group>
            <b-form-group label="متن پیام">
              <b-form-textarea placeholder="متن پیام" v-model="message.body"/>
            </b-form-group>
          </b-form>
        </b-overlay>
      </b-modal>

      <div class="relative-over-x">
        <b-table
          ref="refUserListTable"
          :items="items"
          :small="true"
          responsive
          :fields="columns"
          primary-key="id"
          show-empty
          empty-text="اطلاعاتی یافت نشد"
          style="white-space: nowrap; min-height : 235px"
          @sort-changed="sort($event)"
          no-local-sort

        >
          <template #cell(name)="data">
                        <span dir="ltr">
                            {{ data.item.name }}
                        </span>
          </template>

          <template #cell(symbol)="data">
                        <span dir="ltr">
                            {{ data.item.symbol }}
                        </span>
          </template>

          <template #cell(logo)="data">
            <img :src="data.item.logo" alt="" style="width: 50px; height: auto">
          </template>

          <template #cell(status)="data">
            <b-badge
              pill
              :variant="'light-'+tokenStatus(data.item.status)"
              class="text-capitalize"
            >
              {{ tokenStatusLabel(data.item.status) }}
            </b-badge>
          </template>


          <template #cell(wallets)="data">
            <b-badge
              pill
              variant="light-info"
              class="text-capitalize"
            >
              {{ data.item.wallets.length }}
            </b-badge>
          </template>

          <template #cell(tokens)="data">
            <router-link :to="{
              name : 'Tokens',
               params: { chainId:  data.item.id }
            }">
              مشاهده توکن ها
            </router-link>

          </template>

          <template #cell(action)="{item}">
            <!--              v-if="$havePermission('DETAILS_USERS') ||-->
            <!--                                 $havePermission('UPDATE_USERS') ||-->
            <!--                                 $havePermission('LIST_ORDERS') ||-->
            <!--                                 $havePermission('LIST_TRADES') ||-->
            <!--                                 $havePermission('LIST_MESSAGES')"-->
            <template
            >
              <b-dropdown
                id="dropdown-offset"
                variant="link"
                no-caret
                offset="80px"
                :right="true"
              >
                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>
                <!--                v-if="$havePermission('UPDATE_USERS')"-->
                <b-dropdown-item @click="changeStatus(item)">
                  <feather-icon icon="RefreshCwIcon"/>
                  <span class="align-middle ml-50">تغییر وضعیت</span>
                </b-dropdown-item>

              </b-dropdown>
            </template>
            <!--            <not-allowed v-else/>-->
          </template>

        </b-table>
      </div>

    </b-overlay>
  </b-card>
</template>

<script>
import {
  BCard,
  BButton,
  BTable,
  BDropdown,
  BDropdownItem,
  BBadge,
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BOverlay
} from 'bootstrap-vue'
import SearchAndFilter from "@/layouts/components/SearchAndFilter";
// import NotAllowed from "@/layouts/components/NotAllowed";

export default {
  name: "Wallet",
  props : ['chain'],
  components: {
    // NotAllowed,
    SearchAndFilter,
    BCard,
    BButton,
    BTable,
    BDropdown,
    BDropdownItem,
    BBadge,
    // vSelect,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BOverlay
  },
  data: () => ({
    test: 0,
    pageLength: 3,
    dir: false,
    searchTerm: '',
    userData: [],
    isActive: false,
    messageModal: false,
    perPageOptions: [5, 10, 20, 50, 100],
    items: [],
    loading: false,
    loading2: false,
    message: {
      id: '',
      title: '',
      body: ''
    },
    columns: [
      {
        label: 'نام',
        key: 'name',
        sortable: false,
        searchType: 'text'
      },
      {
        label: 'symbol',
        key: 'symbol',
        sortable: false,
        searchType: 'text'
      },
      {
        label: 'لوگو',
        key: 'logo',
        sortable: false,
        searchType: 'text'
      },
      {
        label: 'وضعیت',
        key: 'status',
        sortable: false,
        searchType: 'select',
        selectOptions: [
          {value: 'ACTIVATED', label: 'فعال'},
          {value: 'DISABLED', label: 'غیرفعال'},]
      },
      {
        label: 'مدیریت',
        key: 'action'
      },
    ],

    modalNativeCoin: false,
    modalNativeCoinInfo: {},
  }),
  computed: {
    tokenStatus() {
      const a = {
        ACTIVATED: 'success',
        DISABLED: 'danger',
        PENDING: 'warning',
        DISABLED_BY_API: 'danger',
      }
      return e => a[e];
    },
    tokenStatusLabel() {
      const a = {
        ACTIVATED: 'فعال',
        DISABLED: 'غیرفعال',
        PENDING: 'در حال بررسی',
        DISABLED_BY_API: 'غیر فعال (provider)',
      }
      return e => a[e];
    },
    // georgianToJallali(){
    //     return e => this.$jmoment(e, 'YYYY-MM-DDTHH:mm:ss').format('dddd jD jMMMM jYYYY')
    // }
  },
  methods: {
    activeOrdersPage(id) {
      this.push('withdraw-orders', id)
    },
    orderHistoryPage(id) {
      this.push('wallet-transactions', id)
    },
    push(to, id) {
      this.$router.push({name: to, query: {email: id}})
    },
    openMessageModal(e) {
      this.message.id = e
      this.messageModal = true
    },


    changeStatus(e) {
      this.$swal({
        title: 'آیا از تغییر وضعیت کیف پول مطمئن هستید؟',
        // text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'بله',
        cancelButtonText: 'خیر',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.value) {
          this.state.loading = true
          const address = `/wallets/${e.id}`
          const wallet = await this.$axios.patch(address,{status : (e.status === 'ACTIVATED' ? 'DISABLED' : 'ACTIVATED')})
          this.$swal({
            icon: 'success',
            title: (wallet.data.data.status === 'ACTIVATED') ? 'کیف پول فعال شد' : 'کیف پول غیر فعال شد',
            confirmButtonText: 'تایید',
            // text: 'Your file has been deleted.',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })

          await this.getData()
        }
      })
    },

    sort(e) {

      let sort = this.$toSnakeCase(e.sortBy)
      // let sort = e.sortBy
      let sorting = e.sortDesc ? 'DESC' : 'ASC'

      this.$router.push({
        query: {
          ...this.$route.query,
          orderBy: sort,
          sorting: sorting
        }
      })

      this.getData(1, this.perPage)

    },
    async getData() {
      this.state.loading = true
      let address = `/chains/${this.chain}/wallets`

      // if (!this.$route.query['orderBy']) {
      //   queryParams['orderBy'] = 'created_at'
      //   queryParams['orderByType'] = 'desc'
      // }

      const res = await this.$axios(
        address
      )
      this.state.loading = false
      this.items = res.data.data
    },

    async downloadExcel() {
      await this.$axios(
        {
          url: '/users',
          method: 'GET',
          responseType: 'blob', // important
          params: {
            export: 'excel',
            ...this.$route.query
          }
        }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', this.$jmoment().format(this.$dateFormat['faDateTime']) + '.xlsx'); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
    }

  },
  mounted() {
    this.getData(1, this.perPage)
  },

}
</script>
<style lang="scss">
[v-cloak] {
  opacity: 0;
}

.nowrap {
  white-space: nowrap;
}

[dir="rtl"] .test-badge {
  font-size: 10px;
  top: -6px;
  left: -5px !important;
  min-width: 16px;
  min-height: 16px;
}
</style>
